<template>
  <div>
    <ActionsModal
      v-if="isAddItemToCartModalOpen"
      :toggle-dialog="isAddItemToCartModalOpen"
      :title="$t('orderProcessCart.dialog.title')"
      :content="$t('orderProcessCart.dialog.content', { productName: itemToSaveInCartName })"
      :confirm-button-text="$t('orderProcessCart.dialog.confirmButtonText')"
      :cancel-button-text="$t('cancel')"
      :dialog-type="DIALOG_TYPES.DANGER"
      @on-cancel="$emit('on-close-add-item-to-cart')"
      @on-close="$emit('on-close-add-item-to-cart')"
      @on-confirm="$emit('on-add-item-to-cart')"
    />
    <ActionsModal
      v-if="isResetModalOpen"
      :toggle-dialog="isResetModalOpen"
      :title="$t('orders.resetOrder')"
      :content="isStocktaking ? $t('orders.resetStocktaking') : $t('orders.resetCatalog')"
      :confirm-button-text="$t('orders.resetOrder')"
      :cancel-button-text="$t('cancel')"
      :dialog-type="DIALOG_TYPES.DANGER"
      @on-cancel="$emit('on-close-reset-order')"
      @on-close="$emit('on-close-reset-order')"
      @on-confirm="$emit('on-reset-order')"
    />
    <ActionsModal
      v-if="isDeleteAllItemsModalOpen"
      :toggle-dialog="isDeleteAllItemsModalOpen"
      :title="$t('orders.resetOrder')"
      :content="$t('orders.deleteItemsInOrder')"
      :confirm-button-text="$t('orders.resetOrder')"
      :cancel-button-text="$t('cancel')"
      :dialog-type="DIALOG_TYPES.DANGER"
      :confirm-button-loading="deleteAllOrderItemsLoading"
      @on-cancel="$emit('on-close-delete-all-items-in-order')"
      @on-close="$emit('on-close-delete-all-items-in-order')"
      @on-confirm="$emit('on-delete-all-items')"
    />
    <ActionsModal
      v-if="isDeleteModalOpen"
      :toggle-dialog="isDeleteModalOpen"
      :title="$t('orders.deleteOrder')"
      :confirm-button-text="$t('orders.deleteOrder')"
      :cancel-button-text="$t('cancel')"
      :dialog-type="DIALOG_TYPES.DANGER"
      :confirm-button-loading="deleteOrderProcessLoading"
      @on-cancel="$emit('on-close-delete-order')"
      @on-close="$emit('on-close-delete-order')"
      @on-confirm="$emit('on-delete-order')"
    >
      <template #content>
        <FormattedText :text="$t('orders.deleteOrderText', { supplier: supplierName })" />
      </template>
    </ActionsModal>

    <ActionsModal
      v-if="isDeleteAllUnavailableItemsModalOpen"
      :toggle-dialog="isDeleteAllUnavailableItemsModalOpen"
      :title="$t('orders.deleteUnavailableItems.title')"
      :confirm-button-text="$t('orders.deleteUnavailableItems.confirmText')"
      :cancel-button-text="$t('orders.deleteUnavailableItems.cancelText')"
      :dialog-type="DIALOG_TYPES.WARNING"
      :confirm-button-loading="deletingUnavailableItems"
      @on-cancel="$emit('on-close-delete-all-unavailable-items')"
      @on-close="$emit('on-close-delete-all-unavailable-items')"
      @on-confirm="$emit('on-delete-all-unavailable-items')"
    >
      <template #content>
        <div class="d-flex flex-column gap-2">
          <p>{{ $t('orders.deleteUnavailableItems.content') }}</p>
          <ul :style="{ maxHeight: '110px', overflowY: 'auto' }">
            <li v-for="product in productsInOpenOrderThatArentAvailable" :key="product.id">
              <p :style="{ fontWeight: '500' }">{{ product.name }}</p>
            </li>
          </ul>
        </div>
      </template>
    </ActionsModal>
  </div>
</template>
<script>
import { ActionsModal, DIALOG_TYPES } from '@/modules/core';
import FormattedText from '@/modules/purchase-management/components/FormattedText.vue';
export default {
  name: 'CartActionModals',
  components: { ActionsModal, FormattedText },
  props: {
    supplierName: { type: String, default: '' },
    isStocktaking: { type: Boolean, default: false },
    itemToSaveInCartName: { type: String, default: '' },
    isResetModalOpen: { type: Boolean, default: false },
    isDeleteModalOpen: { type: Boolean, default: false },
    deletingUnavailableItems: { type: Boolean, default: false },
    isAddItemToCartModalOpen: { type: Boolean, default: false },
    isDeleteAllItemsModalOpen: { type: Boolean, default: false },
    isDeleteAllUnavailableItemsModalOpen: { type: Boolean, default: false },
    deleteOrderProcessLoading: { type: Boolean, default: false },
    deleteAllOrderItemsLoading: { type: Boolean, default: false },
    productsInOpenOrderThatArentAvailable: { type: Array, default: () => [] },
  },
  emits: [
    'on-reset-order',
    'on-delete-order',
    'on-add-item-to-cart',
    'on-delete-all-items',
    'on-close-add-item-to-cart',
    'on-close-reset-order',
    'on-close-delete-order',
    'on-close-delete-all-items-in-order',
    'on-delete-all-unavailable-items',
    'on-close-delete-all-unavailable-items',
  ],
  setup() {
    return {
      DIALOG_TYPES,
    };
  },
};
</script>
